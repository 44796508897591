import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.config.productionTip = false // 阻止生产模式的消息


var _hmt = _hmt || [];
window._hmt = _hmt; // 必须把_hmt挂载到window下，否则找不到（很多网友说需要添加我没加也成功了）
(function() {
	var hm = document.createElement("script")
	hm.src = "https://hm.baidu.com/hm.js?9a8f23b6d1c5e70cb7e769ca3769ac8b"
	var s = document.getElementsByTagName("script")[0]
	s.parentNode.insertBefore(hm, s)
})();


// 定义全局组件
Vue.component("myComponent", {
	render() {
		return < h1 > 公共组件 < /h1>
	}
})
// 全局过滤器
Vue.filter('addZero', function(data) {
	return data < 10 ? '0' + data : data;
})

window.vueVm = new Vue({
	store,
	router,
	render: (h) => h(App),
}).$mount("#app");