import request from '@/utils/axios'

// 发送短信
export function sendMsg(data) {
  return request({
    url: '/sms/ua/sendMsg',
    method: 'post',
    data
  })
}

// 验证码登录
export function smsLogin(data) {
  return request({
    url: '/login/ua/smsLogin',
    method: 'post',
    data
  })
}

// 退出登录
export function loginOut() {
  return request({
    url: '/login/ua/loginOut',
    method: 'post'
  })
}

// 获得登录用户信息
export function loginUserInfo(data) {
  return request({
    url: '/userInfo/ua/loginUserInfo',
    method: 'post',
    data
  })
}

// 获得最近绘画记录
export function getLastRecord(data) {
  return request({
    url: '/drawRecord/getLastRecord',
    method: 'post',
    data
  })
}

// 获得最近绘画记录
export function deleteRecord(data) {
  return request({
    url: '/drawRecord/deleteRecord/'+data,
    method: 'delete'
  })
}

// 获取画笔颜色字典
export function getStrokeBaseColor(data) {
  return request({
    url: '/dict/getStrokeBaseColor',
    method: 'GET'
  })
}

// 上传base64图片
export function saveBase64Image(data) {
  return request({
    url: '/file/ua/saveBase64Image',
    method: 'POST',
	data
  })
}

// 图生图
export function img2img(data) {
  return request({
    url: '/draw/img2img',
    method: 'POST',
	data
  })
}

// 文生图
export function txt2img(data) {
  return request({
    url: '/draw/txt2img',
    method: 'POST',
	data
  })
}
