<template>
	<div>
		<header class="header">
			<div class="container">
				<div class="logo-nav">
					<img @click="goHome()" style="cursor: pointer" src="@/assets/logo-big.png" alt="logo" class="logo" />
					<nav class="nav">
						<ul>
							<li><a href="/">首页</a></li>
							<li><a href="/#/About">关于我们</a></li>
						</ul>
					</nav>
					<div class="login">
						<el-button v-if="!loginFlag" type="primary" @click="openLoginDialog()">登录</el-button>
						<el-button v-else type="primary" @click="toDashboard()">进入控制台</el-button>
					</div>
				</div>
			</div>
		</header>

		<div class="body">
			<el-image src="https://oss.djsmart.vip/app/home06.jpg" fit="scale-down" />
			<div class="hero">
				<div class="title1">AI画图助手</div>
				<div class="title2">释放无限创意，您的AI画图伙伴</div>
				<button class="main-btn" @click="experienceNow()">开始免费体验</button>
			</div>
			<div class="line-box">
				<div class="line-draft">
					<h2 class="line-draft-title">一键智能上色，线稿瞬间焕新</h2>
					<el-carousel class="line-draft-content" height="600px" :interval="5000">
						<el-carousel-item class="line-draft-item" v-for="item in lineDrafts" :key="item">
							<el-image style="border-radius: 1%;" fit="scale-down" :src="item" />
						</el-carousel-item>
					</el-carousel>
				</div>
				
			</div>
			<div class="inspiration-box">
				<div class="inspiration">
					<h2 class="inspiration-title">灵感触手可及，创意即刻变现</h2>
					<div class="inspiration-content">
						<div class="inspiration-content-div">
							<el-carousel height="500px" :interval="3000">
								<el-carousel-item class="inspiration-content-item" v-for="item in inspirations1" :key="item">
									<el-image fit="scale-down" :src="item" />
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="inspiration-content-div">
							<el-carousel height="500px" :interval="2500">
								<el-carousel-item class="inspiration-content-item" v-for="item in inspirations2" :key="item">
									<el-image fit="scale-down" :src="item" />
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="inspiration-content-div">
							<el-carousel height="500px"  :interval="3500">
								<el-carousel-item class="inspiration-content-item" v-for="item in inspirations3" :key="item">
									<el-image fit="scale-down" :src="item" />
								</el-carousel-item>
							</el-carousel>
						</div>
					</div>
				</div>
			</div>
			
			<div class="drawing-box">
				<div class="drawing-board">
					<h2 class="drawing-board-title">多功能画板，在线挥洒艺术才华</h2>
					<div class="drawing-board-content">
						<video width="1200" ref="videoPlayer" src="https://oss.djsmart.vip/app/video01.mp4" loop autoplay muted>您的浏览器不支持视频播放。</video>
					</div>
				</div>
			</div>
			
			<button class="main-btn" @click="experienceNow()">开始免费体验</button>
		</div>

		<footer class="footer">
			<div class="container">
				<p>
					©2024 版权所有 东莞市洞见智能科技有限公司
					<a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2024313776号</a>
				</p>
			</div>
		</footer>
		<LoginDialog :dialogVisible.sync="dialogOpen" />
	</div>
</template>

<script>
import Cookies from 'js-cookie';
import LoginDialog from '@/components/LoginDialog';
import { loginUserInfo } from '@/utils/api';
export default {
	components: {
		LoginDialog
	},
	mounted() {
		this.loadUserInfo();
		this.$refs.videoPlayer.play();
	},
	data() {
		return {
			dialogOpen: false,
			loginFlag: false,
			lineDrafts: ['https://oss.djsmart.vip/app/main14.jpg', 'https://oss.djsmart.vip/app/main12.jpg', 'https://oss.djsmart.vip/app/main15.jpg'],
			inspirations1: ['https://oss.djsmart.vip/app/1008/a1.jpg', 'https://oss.djsmart.vip/app/1008/a2.jpg'],
			inspirations2: ['https://oss.djsmart.vip/app/1008/b1.jpg', 'https://oss.djsmart.vip/app/1008/b2.jpg'],
			inspirations3: ['https://oss.djsmart.vip/app/1008/c1.jpg', 'https://oss.djsmart.vip/app/1008/c2.jpg']
		};
	},
	methods: {
		experienceNow(){
			if(this.loginFlag){
				this.$router.push({ path: '/Dashboard' });
			}else{
				this.dialogOpen = true;
			}
		},
		goHome() {
			window.open('/', '_self', '', true);
		},
		toDashboard() {
			this.$router.push({ path: '/Dashboard' });
		},
		loadUserInfo() {
			var token = Cookies.get('token');
			if (!token || !token.trim()) {
				return;
			}
			loginUserInfo().then((res) => {
				this.loginFlag = res.data !== null;
			});
		},
		openLoginDialog() {
			this.dialogOpen = true;
		}
	}
};
</script>

<style lang="less">
@import '@/views/Home.less';
</style>
