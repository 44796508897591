<template>
	<el-container>
		<el-aside width="250px">
			<img @click="goHome()" style="cursor: pointer" src="@/assets/logo-big.png" alt="logo" class="logo" />
			<el-menu active-text-color="#067BEF" default-active="1" @open="handleOpen" @close="handleClose">
				<!-- <el-menu-item index="1" @click="openUrl('/#/Doodle')">
					<i class="el-icon-location"></i>
					<span slot="title">商品图换背景</span>
				</el-menu-item> -->
				<el-menu-item index="2" @click="openUrl('/#/Draw')">
					<i class="el-icon-goods"></i>
					<span slot="title">线稿图上色</span>
				</el-menu-item>
				<el-menu-item index="3" @click="openUrl('/#/Mywork')">
					<i class="el-icon-location"></i>
					<span slot="title">我的作品</span>
				</el-menu-item>
			</el-menu>

			<!-- 固定在底部的元素 -->
			<div class="fixed-bottom">作品数：{{ recordCount }}</div>
		</el-aside>
		<el-main style="padding: 5px">
			<div class="user-profile">
				<span class="nickname">
					<el-dropdown @command="dropdownClick">
						<span class="el-dropdown-link">
							{{ userInfo.name }}
							<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</span>
				<img :src="userInfo.headImg" class="avatar" />
			</div>
			<div class="iframe-container">
				<iframe ref="iframe" :src="routerUrl" />
			</div>
		</el-main>
	</el-container>
</template>

<script>
import Cookies from 'js-cookie';
import { loginUserInfo, loginOut } from '@/utils/api';

export default {
	components: {},
	data() {
		return {
			recordCount: 0,
			userInfo: {},
			routerUrl: '/#/Draw'
		};
	},
	mounted() {
		this.loadUserInfo();
	},
	methods: {
		openUrl(url) {
			this.routerUrl = url;
		},
		goHome() {
			window.open('/', '_self', '', true);
		},
		dropdownClick(command) {
			console.log('command=====' + command);
			if (command === 'loginOut') {
				loginOut().then((res) => {
					console.log('loginOut=====' + JSON.stringify(res));
					Cookies.remove('token');
				});
				setTimeout(() => {
					this.$router.push({ path: '/' });
				}, 500);
			}
		},
		handleOpen(key, keyPath) {
			console.log(key, keyPath);
		},
		handleClose(key, keyPath) {
			console.log(key, keyPath);
		},
		loadUserInfo() {
			loginUserInfo().then((res) => {
				this.userInfo = res.data;
			});
		}
	}
};
</script>

<style lang="less">
@import '@/views/Dashboard.less';
</style>
